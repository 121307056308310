// import React, { memo } from 'react'
// import Avatar from '@mui/material/Avatar';
// import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { Dropdown } from 'antd';

// import { getIsLoginStatus } from '@/store/modules/home'
// import { UserWrapper } from './style'

// export default memo(function User({ userInfo, setUserInfo, width = '40px', height = '40px' }) {
//     const navigate = useNavigate();
//     const dispatch = useDispatch()

//     const { isLoginStatus } = useSelector(state => ({
//         isLoginStatus: state.home.isLoginStatus
//     }), shallowEqual)

//     const handleClick = () => {
//         if (!isLoginStatus) {
//             navigate('/login')
//         }
//     };

//     const layout = () => {
//         if (!isLoginStatus) {
//             navigate('/login')
//             return
//         }
//         window.localStorage.removeItem('userInfo')
//         window.localStorage.removeItem('token')
//         setUserInfo({})
//         // 刷新页面
//         dispatch(getIsLoginStatus(false))
//         // window.location.reload()
//     }

//     const gotoMoment = () => {
//         navigate('/moment')
//     }

//     const items = [
//         {
//             key: '1',
//             label: (
//                 <span>{isLoginStatus?`账户:${userInfo?.username}`:'未登录'}</span>
//             ),
//         },
//         {
//             key: '2',
//             label: (
//                 <Button onClick={gotoMoment} variant="outlined">进入圈子</Button>
//             ),
//         },
//         {
//             key: '3',
//             label: (
//                 <Button variant="outlined" color="error" onClick={layout}>
//                     {isLoginStatus?'退出登录':'前去登录'}
//                 </Button>
//             ),
//         },
//     ];

//     return (
//         <UserWrapper>
//             <Dropdown
//                 menu={{
//                     items,
//                 }}
//                 placement="bottom"
//                 // trigger="click"
//             >
//                 <Avatar
//                     onClick={handleClick}
//                     style={{
//                         backgroundColor: '#7265e6',
//                         verticalAlign: 'middle',
//                         width,
//                         height,
//                     }}
//                     autoAdjustOverflow={false}
//                 >{userInfo?.username?.slice(0, 3)}</Avatar>
//             </Dropdown>
//         </UserWrapper>
//     )
// })
import React, { memo, useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getIsLoginStatus } from '@/store/modules/home'
import { UserWrapper } from './style'

export default memo(function User({ userInfo, setUserInfo, width = '40px', height = '40px' }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { isLoginStatus } = useSelector(state => ({
        isLoginStatus: state.home.isLoginStatus
    }), shallowEqual)

    // 监听路由变化，关闭 Menu 组件
    useEffect(() => {
        setAnchorEl(null);
    }, [location.pathname]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                setAnchorEl(null);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [anchorEl]);

    const handleClick = (event) => {
        if (isLoginStatus) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login')
        }
    };

    const layout = () => {
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('token')
        setUserInfo({})
        // 刷新页面
        dispatch(getIsLoginStatus(false))
        // window.location.reload()
    }

    const gotoMoment = () => {
        setAnchorEl(null);
        navigate('/moment')
       
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <UserWrapper>
            <div className="user">
                <Avatar
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick} sx={{ width, height, bgcolor: deepPurple[500] }}>{userInfo?.username?.slice(0, 3)}</Avatar>
            </div>
            {userInfo && <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>账户:{userInfo?.username}</MenuItem>
                {/* <MenuItem>
                    <Button onClick={gotoMoment} variant="outlined">进入圈子</Button>
                </MenuItem> */}
                <MenuItem onClick={handleClose} >
                    <Button variant="outlined" color="error" onClick={layout}>
                        退出登录
                    </Button>
                </MenuItem>
            </Menu>}
        </UserWrapper>
    )
})


// import React, { memo, useEffect, useState } from 'react'
// import Avatar from '@mui/material/Avatar';
// import { deepPurple } from '@mui/material/colors';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { Button } from '@mui/material';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';


// import { getIsLoginStatus } from '@/store/modules/home'
// import { UserWrapper } from './style'

// export default memo(function User({ userInfo, setUserInfo, width = '40px', height = '40px' }) {
//     // const [anchorEl, setAnchorEl] = useState(null);
//     // const open = Boolean(anchorEl);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();

//     const [anchorEl, setAnchorEl] = React.useState(null);

//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorEl(null);
//     };
  
//     const open = Boolean(anchorEl);
//     const id = open ? 'simple-popover' : undefined;
  

//     const { isLoginStatus } = useSelector(state => ({
//         isLoginStatus: state.home.isLoginStatus
//     }), shallowEqual)

//     // 监听路由变化，关闭 Menu 组件
//     useEffect(() => {
//         setAnchorEl(null);
//     }, [location.pathname]);

//     useEffect(() => {
//         const handleDocumentClick = (event) => {
//             if (anchorEl && !anchorEl.contains(event.target)) {
//                 setAnchorEl(null);
//             }
//         };

//         document.addEventListener('click', handleDocumentClick);

//         return () => {
//             document.removeEventListener('click', handleDocumentClick);
//         };
//     }, [anchorEl]);

//     // const handleClick = (event) => {
//     //     if (isLoginStatus) {
//     //         setAnchorEl(event.currentTarget);
//     //     } else {
//     //         navigate('/login')
//     //     }
//     // };

//     const layout = () => {
//         window.localStorage.removeItem('userInfo')
//         window.localStorage.removeItem('token')
//         setUserInfo({})
//         // 刷新页面
//         dispatch(getIsLoginStatus(false))
//         // window.location.reload()
//     }

//     const gotoMoment = () => {
//         setAnchorEl(null);
//         navigate('/moment')
       
//     }

//     // const handleClose = () => {
//     //     setAnchorEl(null);
//     // };

//     return (
//         <UserWrapper>
//             <div className="user">
//                 <Avatar
//                     id="basic-button"
//                     aria-controls={open ? 'basic-menu' : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={open ? 'true' : undefined}
//                     onClick={handleClick} sx={{ width, height, bgcolor: deepPurple[500] }}>{userInfo?.username?.slice(0, 3)}</Avatar>
//             </div>
//             {userInfo &&   <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//                 <MenuItem onClick={handleClose}>账户:{userInfo?.username}</MenuItem>
//                 <MenuItem>
//                     <Button onClick={gotoMoment} variant="outlined">进入圈子</Button>
//                 </MenuItem>
//                 <MenuItem onClick={handleClose} >
//                     <Button variant="outlined" color="error" onClick={layout}>
//                         退出登录
//                     </Button>
//                 </MenuItem>
//             </Popover>}
//         </UserWrapper>
//     )
// })



