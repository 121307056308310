import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const getIsShowHeader = createAsyncThunk("fetchcData",  (payload, { dispatch }) => {
  dispatch(changeIsShowHeader(payload))
})

export const getIsLoginStatus = createAsyncThunk("fetchcData",  (payload, { dispatch }) => {
  dispatch(changeIsLoginStatus(payload))
})

const homeSlice = createSlice({
  name: 'home',
  // 初始化数据
  initialState: {
    isShowHeader: true,
    isLoginStatus:JSON.parse(window.localStorage.getItem('userInfo'))?true:false
  },
  reducers: {
    changeIsShowHeader(state, { payload }) {
      state.isShowHeader = payload
    },
    changeIsLoginStatus(state, { payload }) {
      state.isLoginStatus = payload
    }
  }

})

export const {
  changeIsShowHeader,
  changeIsLoginStatus
} = homeSlice.actions

export default homeSlice.reducer;