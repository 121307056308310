import React, { memo, useState } from 'react'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LightModeIcon from '@mui/icons-material/LightMode';
import { NavLink } from 'react-router-dom';

import { RightWrapper } from './style'
import User from '../user';

export default memo(function HeaderRight({ toggleTheme, themeStatus, setUserInfo, userInfo }) {
  const [thisAction, setThisAction] = useState(0)

  const btns = [
    {
      title: '简历',
      path: '/aboutme'
    },
    {
      title: '首页',
      path: '/home'
    },
    {
      title: '前端知识',
      path: '/blog'
    },
    {
      title: '动态社区',
      path: '/moment'
    },
    {
      title: '旅拍',
      path: '/travelBeat'
    },
    {
      title: '生活',
      path: '/life'
    }
  ]

  const handleNavto = (index) => {
    setThisAction(index)
  }

  return (
    <RightWrapper>
      {btns.map((item, index) => {
        return <NavLink
          className='link'
          to={item.path}
          key={index}
          activeClassName="active"
          onClick={() => handleNavto(index)}
        >
            {item.title}
        </NavLink>
      })}
      <div className="theme">
        {themeStatus ? <Brightness4Icon onClick={toggleTheme} /> : <LightModeIcon onClick={toggleTheme} />}
        {/* <Switch checked={themeStatus} defaultChecked={themeStatus} onChange={toggleTheme} /> */}
      </div>
      <User userInfo={userInfo} setUserInfo={setUserInfo}/>
    
    </RightWrapper>
  )
})
