import styled from "styled-components"

export const RightWrapper=styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content:space-around;
  z-index: 3;
  font-weight: 700;
  font-size: 16px;
  .link {
    transition: color .25s;
    color: ${(props) => props.theme.textColor};
    cursor: pointer;
    &:hover {
      color: #0093E9;
    }
  }
  .active {
    /* 字体渐变色 */
    ${(props) => props.theme.customStyles.fontColor}
    /* border-bottom: 2px solid #6AC1CF; */
    font-size: 17px;
    font-weight: 500px;
  }
  .theme {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`