import React, { memo, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import routes from "@/router";
import AppHeader from "@/components/app-header";
import AppFooter from "@/components/app-footer";
import { MyThemeProvider } from "@/assets/themes/themeProvider";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./assets/themes/style.css";
import { getThemeStatus } from "./store/modules/global";

export default memo(function App() {
  // 获取主题状态，头部是否展示状态
  const { themeStatus, isShowHeader } = useSelector(
    (state) => ({
      themeStatus: state.global.themeStatus,
      isShowHeader: state.detail.isShowHeader,
    }),
    shallowEqual
  );

  const location = useLocation();
  console.log(location.pathname);
  const dispatch = useDispatch();

  const toggleTheme = () => {
    const newMode = !themeStatus;
    dispatch(getThemeStatus(newMode));
  };

  useEffect(() => {

    // document.addEventListener('UniAppJSBridgeReady', function() {
      window.uni.postMessage({
        data: {
            action: 'message',
            url:window.location.href,
        }
    });
      window.uni.webView.getEnv(function(res) {
        // console.log('当前环境：' + JSON.stringify(res));
      });
      // console.log(window.uni)
      // window.uni.webView
    // });
  });

  return (
    <MyThemeProvider isDarkMode={themeStatus}>
      <AppPage>
        <ToastContainer />
        {!location.pathname.includes("love") && (
          <AppHeader
            toggleTheme={toggleTheme}
            isShowHeader={isShowHeader}
            themeStatus={themeStatus}
          ></AppHeader>
        )}
        {useRoutes(routes)}
        <AppFooter></AppFooter>
      </AppPage>
    </MyThemeProvider>
  );
});

const AppPage = styled.div`
  padding-top: 64px;
  color: ${(porps) => porps.theme.textColor};
  background-color: ${(porps) => porps.theme.primaryColor};
  transition: all 0.5 ease;
`;
