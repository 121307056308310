import styled from "styled-components"

export const HeaderWrapper=styled.div`
  position: fixed;
  top:0;
  width: 100%;
  z-index: 99;
  transition: border 0.3s;
  background-color: ${props=>props.isshowbgc?props.theme.primaryColor:'transparent'};
  border-bottom:${props=>props.isshowbgc?'1px solid '+props.theme.textColor:'0px solid transparent'};
  transition: all 0.9s;
  font-weight: 700;
  .box{
  display: flex;
  height: 64px;
  margin: 0 auto;
  max-width: 1200px;
  z-index: 99;
  color: ${(props) => props.theme.textColor};
  }
`