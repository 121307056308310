import React, { memo, useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Switch from '@mui/material/Switch';
import GitHubIcon from '@mui/icons-material/GitHub';

import { PhoneWrapper, Appearance, StyledList } from './style'
import { NavLink } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPhoneIsShowHeader } from '@/store/modules/global';
import IconJueJin from '@/assets/images/svg/juejin'
import User from '../user';

export default memo(function PhoneBar({ toggleTheme, themeStatus, userInfo, setUserInfo }) {
    // 获取移动端头部是否展开全局开关
    const { isShowPhoneHeader } = useSelector(state => ({
        isShowPhoneHeader: state.global.isShowPhoneHeader
    }), shallowEqual)

    const [isShow, setIsShow] = useState(isShowPhoneHeader)

    const dispatch = useDispatch()

    useEffect(() => {
        setIsShow(isShowPhoneHeader)
    }, [isShowPhoneHeader])

    return (
        <PhoneWrapper>
            <div className="logo">
                <span>YaBo-Blog</span>
                <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://github.com/dahaisyb">
                    <GitHubIcon />
                </a>
                <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://juejin.cn/user/4020277027286760">
                    <IconJueJin />
                </a>
            </div>
            <div className="user">
                <MenuIcon style={{ cursor: 'pointer' }} onClick={() => dispatch(getPhoneIsShowHeader(true))} />
                <User userInfo={userInfo} setUserInfo={setUserInfo} width='24px' height='24px' />
            </div>

            <Drawer
                anchor='top'
                open={isShow}
                onClose={() => dispatch(getPhoneIsShowHeader(false))}
                className='drawer'
            >
                <StyledList onClick={() => dispatch(getPhoneIsShowHeader(false))}>

                    <NavLink activeClassName="active" to='/aboutme' className='nav-item '>
                        <div className='list-item'>AboutMe</div>
                    </NavLink>
                    <NavLink activeClassName="active" to='/home' className='nav-item '>
                        <div className='list-item'>Home</div>
                    </NavLink>
                    <NavLink activeClassName="active" to='/blog' className='nav-item'>
                        <div className='list-item'>Blog</div>
                    </NavLink>
                    <NavLink activeClassName="active" to='/travelBeat' className='nav-item'>
                        <div className='list-item'>TravelBeat</div>
                    </NavLink>


                    <NavLink activeClassName="active" to='/moment' className='nav-item'>
                        <div className='list-item'>Moment</div>
                    </NavLink>


                    <NavLink activeClassName="active" to='/life' className='nav-item'>
                        <div className='list-item'>
                            Life
                        </div>
                    </NavLink>

                    <Appearance>
                        <span>Appearance</span>
                        <Switch defaultChecked={themeStatus} onChange={toggleTheme} />
                    </Appearance>
                </StyledList>
            </Drawer>
        </PhoneWrapper>
    )
})
