import styled from "styled-components";

export const PhoneWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 20px;
height: 64px;
/* background-color: ${(porps) => porps.theme.primaryColor}; */
color: ${(props) => props.theme.textColor};
.drawer{
    background-color: ${(porps) => porps.theme.primaryColor};
    color: ${(props) => props.theme.textColor};
}
.logo {
    display: flex;
    align-items: center;
    span{
        margin-right: 20px;
    }
    a {
        margin-right: 10px;
    }
}
.user {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

`

export const Appearance = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 8px auto;
    border-radius: 8px;
    height: 48px !important;
    width: 60% !important;
    background-color: pink;
    background-color: ${(porps) => porps.theme.cardBgColor};
    color: ${(props) => props.theme.textColor};
`
export const StyledList = styled.div`
    padding: 50px 0;
    background-color: ${(porps) => porps.theme.primaryColor};
    a{
        color: ${(props) => props.theme.textColor};
    }
    .list-item{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-bottom: 1px solid #2E2E33;
        &:hover {
            ${props => props.theme.customStyles.fontColor}
        }
    }
    .active {
            /* 字体渐变色 */
            color: #0093E9 !important;
                /* ${(props) => props.theme.customStyles.fontColor} */
            /* border-bottom: 2px solid #6AC1CF; */
            font-size: 17px;
            font-weight: 500px;
        }
`